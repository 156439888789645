
import { ref, onMounted, watch, Ref } from "vue";
import { defineComponent } from "vue";
import Api from "../../services/Api";
import s3 from '../../services/S3Service'
import useAlert from "../../composables/Alert";
import Button from "@/components/Button.vue"
import { Modal } from "bootstrap";
import ModalPlanilhaFiltro from "./components/ModalPlanilhaVeiculo.vue";
import ModalDownloadPlanilhaVeiculo from "./components/ModalDownloadPlanilhaVeiculo.vue";
import moment from "moment";
import axios from "axios";


export default defineComponent({
  name: "UploadPlanilhasVeiculo",
  components: {
    Button,
    ModalPlanilhaFiltro,
    ModalDownloadPlanilhaVeiculo,
  },

  setup() {

    const { showTimeAlert, showConfirmAlert} = useAlert();
    const buttonText = ref('Enviar planilha');
    const botaoDesabilitado = ref(false);
    const arquivo: Ref<any> = ref();
    const marca: Ref<string | null> = ref(null);
    const fileInput: Ref<any> = ref(null);
    const marcas: Ref<any> = ref([]);
    const historicoArquivos: Ref<any> = ref([]);
    const qtdArquivosSalvos: Ref<number> = ref(0)
    const file: Ref<any> = ref(null);
    const loadingUpload: Ref<boolean> = ref(false);
    const rodandoFila = ref(false)
    const alteraRodandoFila = (valor: boolean) => rodandoFila.value = valor

    const caminhoDoArquivo = "AtriaLub/planilhas/veiculos/";

    const arquivoSelecionado: Ref<any> = ref("")
    const modalPlanilhaFiltro: Ref<any> = ref()
    const modalDownloadPlanilhaFiltro: Ref<any> = ref()

    const openInput = () => fileInput.value.click();

    const uploadFile = async (event: any) => {
      const file = event.target.files[0]
      arquivo.value = file
    };

    async function getToken() {
      return localStorage.getItem('token_usuario');
    };

    function dropHandler(e) {

      stopDefault(e)

      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...e.dataTransfer.items].forEach((item) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            file.value = item.getAsFile();
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...e.dataTransfer.files].forEach((fileParam) => {
          file.value = fileParam;
        });
      }

      arquivo.value = file.value
    }

    function stopDefault(e) {
      // Prevent default behavior (Prevent file from being opened)
      e.stopPropagation();
      e.preventDefault();
    }

    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    function limparArquivo() {
      arquivo.value = null
    }

    async function enviarPlanilha() {
      arquivo.value.idMarca = marca.value;
      const timeStamp = (new Date()).getTime();
      const caminhoAws = caminhoDoArquivo + timeStamp + "-" + arquivo.value.name;
      const arquivoBlob = new Blob([arquivo.value]);
      const formData = new FormData();
      formData.append('nome', `${timeStamp}-${arquivo.value.name}`);
      formData.append('arquivo', arquivoBlob, arquivo.value.name);

      try{
        buttonText.value = 'Enviando...';
        botaoDesabilitado.value = true;

        const retorno = await axios.post("processaPlanilhaVeiculos", formData, {
            headers: {
                'Allow-Access-Control-Origin': '*',
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${await getToken()}`,
            },
        });

        if(retorno.status === 200){
            s3.upload({ Key: `${caminhoDoArquivo}${timeStamp}-${arquivo.value.name}`, Body: arquivo.value, ACL: "public-read" }, async (error, data) => {
              if (data.Key !== null && typeof data.Key !== 'undefined') {
                showTimeAlert("Planilha enviada com sucesso");
                buttonText.value = 'Enviar planilha';
                botaoDesabilitado.value = false;
                pegarListaDePlanilhas();
                arquivo.value = null;
                marca.value = null;
              }
            });
        }
      }catch(error: any){
        let mensagensError = "Erro ao fazer upload!";
        if(error.response){
            // O servidor respondeu com um status fora do intervalo 2xx
            if(error.response.status == 422){
              mensagensError = error.response.data.errors?.map((erro) => {
                return `Error  na linha ${erro.row} : ${erro.errors[0]} <br>`;
              });
            }else if(error.response.status == 500){
              mensagensError = `Erro no processamento da planilha<br>`;
            }
        }else if(error.request){
            // A requisição foi feita, mas não houve resposta
            mensagensError = `Erro na resposta do servidor<br>`;
        }else{
            // Algo deu errado ao configurar a requisição
            mensagensError = `Erro ao configurar a requisição<br>`;  
        }
        showConfirmAlert("", "error", " ", "OK", "center");
        buttonText.value = 'Enviar planilha';
        botaoDesabilitado.value = false;
        const divMessage = document.querySelector("#swal2-content");
        const modal = document.querySelector(".swal2-modal");
        modal?.classList.add("widthModal");
        //injeta os erros direto no html
        if (divMessage) divMessage.innerHTML = mensagensError.toString().replace(/,/g," ");
      }
    }

    function pegarListaDePlanilhas() {

      s3.listObjects({ Bucket: 'varejo-storage', Delimiter: '/', Prefix: 'AtriaLub/planilhas/veiculos/', }, async(_, data) => {
        const lista: any[] = [];

        data.Contents.sort((a, b) => b.LastModified - a.LastModified)
        const { filas } = (await Api.get("getFilas")).data || []

        data.Contents.forEach(i =>{
          const item = { 
            nome: i.Key.replace('AtriaLub/planilhas/veiculos/', ''),
            status: 'concluído',
            erro: false,
            detalheErro: '',
            data: formatData(i.LastModified),
            pasta: i.Key,
          }

          filas.forEach(j => {
            if(item.nome === j.nome.replace('tabela_veiculos_', '')) {
              item.erro = j.erro;
              item.status = j.status;
              item.detalheErro = j.detalheErro;
            }
          });
          
          lista.push(item)
        })
        historicoArquivos.value = lista.slice(0, 10)
        qtdArquivosSalvos.value = data.Contents.length
      })
    }

  function formatData(data) {
    const dataFormatada = moment(data).format('DD/MM/YYYY');
    return dataFormatada;
  }

    ///////////////////// MODAL PRODUTO LOG /////////////////////

    function abreModal(arquivo?: any) {
      arquivoSelecionado.value = arquivo || {}
      modalPlanilhaFiltro.value.show()
    }

    async function fechaModal() {
      arquivoSelecionado.value = ""
      modalPlanilhaFiltro.value.hide()
    }

    ///////////////////// MODAL PRODUTO LOG /////////////////////

    //////////////// MODAL DOWNLOAD PLANILHA ////////////////////

    function abreModalDownload(arquivo?: any) {
      arquivoSelecionado.value = arquivo || {}
      modalDownloadPlanilhaFiltro.value.show()
    }

    function fechaModalDownload() {
      arquivoSelecionado.value = ""
      modalDownloadPlanilhaFiltro.value.hide()
    }

    //////////////// MODAL DOWNLOAD PLANILHA ////////////////////
    

    watch(() => rodandoFila.value, async () => { pegarListaDePlanilhas() }, { deep: true })

    onMounted(async() => {

      modalPlanilhaFiltro.value = new Modal(document.querySelector(`#ModalPlanilhaVeiculos`));

      modalDownloadPlanilhaFiltro.value = new Modal(document.querySelector(`#ModalDownloadPlanilhaVeiculos`));

      // getMarcas()
      pegarListaDePlanilhas()

    })

    return {

      dropHandler,
      stopDefault,
      arquivo,
      limparArquivo,
      buttonText,
      botaoDesabilitado,
      enviarPlanilha,
      marcas,
      marca,
      historicoArquivos,
      formatBytes,
      openInput,
      uploadFile,
      fileInput,
      formatData,
      history,
      qtdArquivosSalvos,
      abreModal,
      fechaModal,
      arquivoSelecionado,
      rodandoFila,
      alteraRodandoFila,
      abreModalDownload,
      fechaModalDownload,
      loadingUpload,
    }
  },
});
